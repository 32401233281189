import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpAndFaqService {

  private listener$$: Subject<boolean> = new Subject<boolean>();
  listener$: Observable<boolean> = this.listener$$.asObservable();

  private routeListener$$: Subject<boolean> = new Subject<boolean>();
  routeListener$: Observable<boolean> = this.routeListener$$.asObservable();


  constructor() { }

  openDrawer() {
    this.listener$$.next(true);
  }
  closeDrawer() {
    this.listener$$.next(false);
  }

  hideSideNavMenu() {
    this.routeListener$$.next(false);
  }

  showSideNavMenu() {
    this.routeListener$$.next(true);
  }


}
